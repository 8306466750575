import { IIdName } from "@app/shared/interfaces/id-name.interface";

export const InspectionFrequencyOptions: IIdName[] = [
	{
		id: 'weekly',
    name: 'Weekly'
	},
  {
		id: 'monthly',
    name: 'Monthly'
	},
  {
		id: 'quartely',
    name: 'Quarterly'
	},
  {
		id: 'annually',
    name: 'Annualy'
	}
]